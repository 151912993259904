import { useContext } from 'react'
import SnackContext, { SnackInfo } from './snackContext'

const useSnacks = () => {
  const {
    snacks,
    showSnack: contextShowSnack,
    removeSnack: contextRemoveSnack,
  } = useContext(SnackContext)

  const removeSnack = (key: string) =>
    contextRemoveSnack && contextRemoveSnack(key)

  const showSnack = (message: string) => {
    const snackInfo: SnackInfo = { message, key: `${message}-${Date.now()}` }
    contextShowSnack && contextShowSnack(snackInfo)
    return snackInfo
  }

  return { snacks, showSnack, removeSnack }
}

export default useSnacks
