/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import Card, { CardActionButtons } from '@material/react-card'
import { Button } from '@material/react-button'
import { Body1, Headline4, Body2 } from '@material/react-typography'
import { useTranslation, Trans } from 'react-i18next'
import useProfile from '../../hooks/useProfile'
import BasicInformationDisplay from './BasicInformationDisplay'
import ProfilePictures from './ProfilePictures'
import PageHeadline from '../../components/PageHeadline'
import { RouteComponentProps } from 'react-router'
import AlternateHoverEmoji from '../../components/AlternateHoverEmoji'
import { Link } from 'react-router-dom'

const Profile = ({ history }: RouteComponentProps) => {
  const { profile, profileIsLoaded } = useProfile()
  const { t } = useTranslation()

  return (
    <Fragment>
      <PageHeadline>{t('profile.headline_profile')}</PageHeadline>

      <Headline4
        css={css`
          margin-bottom: 0;
        `}
      >
        {t('profile.basic_information.headline')}
      </Headline4>

      <Body1>{t('profile.basic_information.explaination')}</Body1>

      <Card
        css={css`
          padding: 1rem;
          margin-bottom: 2rem;
        `}
      >
        {profileIsLoaded && (
          <Fragment>
            <BasicInformationDisplay
              profile={profile}
            ></BasicInformationDisplay>
            <CardActionButtons>
              <Button onClick={() => history.push(`/profile/basicInfo`)}>
                {t('button.edit')}
              </Button>
            </CardActionButtons>
          </Fragment>
        )}
      </Card>

      <Headline4
        css={css`
          margin-bottom: 0;
        `}
      >
        {t('profile.pictures.headline')}{' '}
        <AlternateHoverEmoji emoji="📷" alternateEmoji="📸" timeout={200} />
      </Headline4>

      <Body1>{t('profile.pictures.explaination')}</Body1>
      <Body2
        css={css`
          opacity: 0.8;
        `}
      >
        {t('profile.pictures.edit_hint')}
      </Body2>

      <ProfilePictures />

      <Headline4
        css={css`
          margin-bottom: 0;
        `}
      >
        {t('profile.account.headline')}
      </Headline4>
      <Body1>
        <Trans i18nKey="profile.account.body">
          <Link to="/account">Account Page</Link>
        </Trans>
      </Body1>
    </Fragment>
  )
}

export default Profile
