/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import IconButton from '@material/react-icon-button'
import MaterialIcon from '@material/react-material-icon'
import { useState, ReactElement, MouseEvent, useRef } from 'react'
import MenuSurface, { Corner } from '@material/react-menu-surface'

const IconMenu = ({
  children,
  ButtonElement,
}: {
  children: ReactElement | ReactElement[]
  ButtonElement?: ReactElement
}) => {
  const [open, setOpen] = useState(false)
  const anchor = useRef<HTMLDivElement>(null)

  const onOpenerClick = (e: MouseEvent<any>) => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <div className="mdc-menu-surface--anchor" ref={anchor}>
      {!!ButtonElement && <div onClick={onOpenerClick}>{ButtonElement}</div>}
      {!ButtonElement && (
        <IconButton onClick={onOpenerClick}>
          <MaterialIcon
            css={css`
              opacity: 0.6;
              &:hover {
                opacity: 1;
              }
            `}
            icon="more_vert"
          />
        </IconButton>
      )}
      <MenuSurface
        open={open}
        anchorCorner={Corner.TOP_LEFT}
        onClose={() => setOpen(false)}
        anchorElement={anchor.current || undefined}
      >
        {Array.isArray(children) &&
          children.map((child, index) => (
            <div key={index} onClick={() => setOpen(false)}>
              {child}
            </div>
          ))}
        {!Array.isArray(children) && (
          <div onClick={() => setOpen(false)}>{children}</div>
        )}
      </MenuSurface>
    </div>
  )
}

export default IconMenu
