/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'

const AlternateHoverEmoji = ({
  emoji,
  alternateEmoji,
  timeout = 0,
}: {
  emoji: string
  alternateEmoji: string
  timeout?: number
}) => {
  const [useAlternate, setUseAlternate] = useState(false)

  const triggerAlternate = () => {
    setUseAlternate(true)
    if (timeout) {
      setTimeout(() => {
        setUseAlternate(false)
      }, timeout)
    }
  }

  const onLeave = () => {
    // Timeout overwrites changing based on hover state.
    if (!timeout) setUseAlternate(false)
  }

  return (
    <span
      onMouseEnter={triggerAlternate}
      onMouseLeave={onLeave}
      css={css`
        display: inline-block;
      `}
      role="img"
      aria-label="waving hand emoji"
    >
      {useAlternate ? alternateEmoji : emoji}
    </span>
  )
}

export default AlternateHoverEmoji
