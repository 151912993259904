import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfilePicture } from '../../hooks/useProfilePictures'

const initialState: ProfilePicture[] = []

const profilePictureSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfilePictures(
      state,
      action: PayloadAction<{ pictures: ProfilePicture[] }>
    ) {
      return action.payload.pictures
    },
  },
})

export const { setProfilePictures } = profilePictureSlice.actions

export default profilePictureSlice.reducer
