/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ReactElement } from 'react'

const Wrapper = ({ children }: { children: ReactElement | ReactElement[] }) => (
  <div
    css={css`
      max-width: 50rem;
      margin: 0 auto;
      padding-bottom: 2rem;
    `}
  >
    {children}
  </div>
)

export default Wrapper
