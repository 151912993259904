import React from 'react'
import { Snackbar } from '@material/react-snackbar'
import useSnacks from './useSnacks'

const SnackDisplay = () => {
  const { snacks, removeSnack } = useSnacks()
  if (!snacks || snacks.length === 0) return null

  const snack = snacks[0]

  return (
    <Snackbar
      key={snack.key}
      message={snack.message}
      onClose={() => removeSnack(snack.key)}
      timeoutMs={snack.durationMs || 5000}
    />
  )
}

export default SnackDisplay
