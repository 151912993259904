// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app'

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAHjm-V9Y6BXElZHMF6jldGKbXBCH8K5lw',
  authDomain: 'momentum-vitae.firebaseapp.com',
  databaseURL: 'https://momentum-vitae.firebaseio.com',
  projectId: 'momentum-vitae',
  storageBucket: 'momentum-vitae.appspot.com',
  messagingSenderId: '936946163972',
  appId: '1:936946163972:web:edc7a168f28a1fb821b051',
}

firebase.initializeApp(firebaseConfig)

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .then(() => console.log('Persistance: enabled successfully.'))
  .catch(err => {
    if (err.code === 'failed-precondition') {
      console.error('Persistance: already running in another tab.')
    } else if (err.code === 'unimplemented') {
      console.error('Persistance: preconditions not fulfilled. ')
    }
  })

export default firebase
