import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { Body1, Headline4, Body2 } from '@material/react-typography'
import List, { ListItem } from '@material/react-list'
import useBreakpoints from '../../hooks/useBreakpoints'
import WavingHand from '../../components/WavingHand'
import { useTranslation, Trans } from 'react-i18next'

const Home = () => {
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Headline4>
        Welcome to MoVi <WavingHand />
      </Headline4>
      <Body1>{t('home.slogan')}</Body1>
      <Body2>
        <Trans i18nKey="home.explaination">
          <Link to="/entries">Entries</Link>
          <Link to="/resumes">Resumes</Link>
          <Link to="/profile">Profile</Link>
        </Trans>
      </Body2>

      {/* Fragment needed here for weird TypeScript thing. */}
      <Fragment>
        {isMobile && (
          <List tag="nav">
            <Link to="/entries">
              <ListItem>{t('pages.entries')}</ListItem>
            </Link>
            <Link to="/resumes">
              <ListItem>{t('pages.resumes')}</ListItem>
            </Link>
            <Link to="/profile">
              <ListItem>{t('pages.profile')}</ListItem>
            </Link>
          </List>
        )}
      </Fragment>
    </Wrapper>
  )
}

export default Home
