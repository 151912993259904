/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from '../../firebase'
import useAccount from '../../hooks/useAccount'
import { Redirect } from 'react-router'

const Login = ({ location }: { location?: any }) => {
  const { isLoggedIn } = useAccount()
  const { t } = useTranslation()

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  }

  // We might have been redirected here from another page.
  // In that case we could go back there upon successful login.
  const { from } = location.state || { from: { pathname: '/' } }

  if (isLoggedIn) {
    return <Redirect to={from}></Redirect>
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h1>
        <span role="img" aria-label="rocker emoji">
          👩‍🎤
        </span>{' '}
        MoVi.rocks{' '}
        <span role="img" aria-label="rocker emoji">
          👨‍🎤
        </span>
      </h1>

      <p>{t('login.paragraph')}</p>

      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  )
}

export default Login
