import useAccount from './useAccount'
import firebase from '../firebase'
import { useEffect } from 'react'
import { CVEntry } from './useCVEntry'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import { setEntries } from '../modules/entries/CVEntries.slice'
import useRegistrations from './useRegistrations'

const db = firebase.firestore()

const byActuality = (a: CVEntry, b: CVEntry) => {
  if (a.isOngoing) return -1
  if (b.isOngoing) return 1
  if (a.end && b.end && a.end.year !== b.end.year)
    return b.end.year - a.end.year
  if (a.end && b.end) return b.end.month - a.end.month
  return 0
}

const byCategoryId = (categoryId: string) => (entry: CVEntry) =>
  entry.categoryId === categoryId

const useCVEntries = (categoryId: string) => {
  const { uid, isLoggedIn } = useAccount()
  const cvEntriesCollectionPath = `users/${uid}/cvEntries`

  const cvEntries = useSelector((state: RootState) =>
    state.cvEntries.filter(byCategoryId(categoryId)).sort(byActuality)
  )
  const dispatch = useDispatch()
  const { isRegistered, addRegistration } = useRegistrations()

  useEffect(() => {
    if (!isLoggedIn) return
    if (isRegistered(cvEntriesCollectionPath)) return
    addRegistration(cvEntriesCollectionPath)
    db.collection(cvEntriesCollectionPath).onSnapshot(snapshot => {
      const data = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })) as CVEntry[]
      data.sort(byActuality)
      dispatch(setEntries({ cvEntries: data }))
    })
  }, [
    isLoggedIn,
    cvEntriesCollectionPath,
    dispatch,
    isRegistered,
    addRegistration,
  ])

  // TODO this should better take categoryId.
  // Then we can hopefully remove the need to payy categoryId to useCVEntries.
  const addEntry = (newEntry: Partial<CVEntry>) => {
    db.collection(cvEntriesCollectionPath).add({
      ...newEntry,
      categoryId: categoryId,
    })
  }

  const updateEntry = (entryId: string, entryUpdate: Partial<CVEntry>) => {
    db.doc(`${cvEntriesCollectionPath}/${entryId}`).update(entryUpdate)
  }

  const deleteEntry = (entryId: string) => {
    db.collection(cvEntriesCollectionPath)
      .doc(entryId)
      .delete()
  }

  return {
    entries: cvEntries,
    addEntry,
    deleteEntry,
    updateEntry,
  }
}

export default useCVEntries
