/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ProfilePicture } from '../../hooks/useProfilePictures'
import { Caption } from '@material/react-typography'
import { useRef } from 'react'

const gutter = '1rem'

const ProfilePictureItem = ({
  picture,
  onClick,
}: {
  picture: ProfilePicture
  onClick?: () => void
}) => {
  const listItem = useRef<HTMLLIElement>(null)
  const imageWidth = listItem.current ? listItem.current.clientWidth : 0
  const imageHeight =
    (imageWidth * picture.dimensions.height) / picture.dimensions.width

  return (
    <li
      ref={listItem}
      css={css`
        width: calc(25% - ${gutter});
        list-style-type: none;
        /* 
        Use the gutter for only the first three elements.
        This way we can have the last one aligned to the grid.
      */
        margin-right: calc(4 * ${gutter} / 3);
        margin-bottom: calc(4 * ${gutter} / 3);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        ${onClick ? 'cursor: pointer;' : ''}
        &:nth-of-type(4n) {
          margin-right: 0;
        }
        @media (max-width: 50rem) {
          width: calc(50% - ${gutter});
          margin-right: calc(2 * ${gutter});
          margin-bottom: calc(2 * ${gutter});
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      `}
      onClick={onClick}
    >
      <div
        css={css`
          ${imageHeight &&
            css`
              height: ${imageHeight}px;
            `}
          background-color: #eeeeee;
          background-image: url(${picture.chipImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `}
      >
        <img
          css={css`
            width: 100%;
          `}
          alt={picture.title}
          src={picture.image}
        />
      </div>
      <Caption>{picture.title}</Caption>
    </li>
  )
}

export default ProfilePictureItem
