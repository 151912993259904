/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Route, RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router'

import Wrapper from '../../components/Wrapper'
import Entries from './Entries'
import NewCVEntry from './NewCVEntry'
import EditCVEntryPage from './EditCVEntryPage'

const EntriesPage = ({ match }: RouteComponentProps) => {
  return (
    <Wrapper>
      <Route path={match.path} exact component={Entries} />
      <Route
        path={`${match.path}/new-entry/:categoryId`}
        component={NewCVEntry}
      />
      <Route path={`${match.path}/:entryId/edit`} component={EditCVEntryPage} />
    </Wrapper>
  )
}

export default withRouter(EntriesPage)
