import firebase from '../firebase'
import { useEffect, useReducer } from 'react'
import useAccount from './useAccount'
import useRegistrations from './useRegistrations'

interface State<T> {
  isLoaded: boolean
  document?: T
}

interface Action<T> {
  type: 'loaded'
  document: T
}

const useFireDocument = <T>(
  documentPath: string,
  onUpdate?: (data: T) => void
) => {
  const db = firebase.firestore()
  const { isLoggedIn } = useAccount()
  const { isRegistered, addRegistration } = useRegistrations()

  const initialState = {
    isLoaded: false,
  }

  const documentReducer = (state: State<T>, action: Action<T>) => {
    switch (action.type) {
      case 'loaded':
        return { isLoaded: true, document: action.document }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(documentReducer, initialState)

  useEffect(() => {
    if (!isLoggedIn) return
    if (isRegistered(documentPath)) return
    addRegistration(documentPath)
    db.doc(documentPath).onSnapshot(snapshot => {
      const documentData = {
        ...snapshot.data(),
        id: snapshot.id,
      } as unknown
      onUpdate && onUpdate(documentData as T)
      !onUpdate && dispatch({ type: 'loaded', document: documentData as T })
    })
  }, [
    isLoggedIn,
    documentPath,
    dispatch,
    db,
    isRegistered,
    addRegistration,
    onUpdate,
  ])

  const updateDocument = (update: Partial<T>) => {
    db.doc(documentPath)
      .update(update)
      .catch(() => db.doc(documentPath).set(update))
  }

  const deleteDocument = () => {
    db.doc(documentPath).delete()
  }

  return {
    document: state.document,
    isLoaded: state.isLoaded,
    deleteDocument,
    updateDocument,
  }
}

export default useFireDocument
