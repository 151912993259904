/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { RouteComponentProps } from 'react-router'
import { Headline3 } from '@material/react-typography'
import BasicInformationForm from './BasicInformationForm'
import useProfile from '../../../hooks/useProfile'
import { useTranslation } from 'react-i18next'

const EditBasicInfoPage = ({ history }: RouteComponentProps) => {
  const { profile, updateProfile, profileIsLoaded } = useProfile()
  const { t } = useTranslation()

  if (!profileIsLoaded) return null

  return (
    <div>
      <Headline3
        css={css`
          margin-bottom: 2rem;
          margin-top: 1rem;
        `}
      >
        {t('profile.basic_information.headline')}
      </Headline3>

      <BasicInformationForm
        profile={profile}
        onUpdate={update => {
          updateProfile(update)
          history.goBack()
        }}
        onCancel={() => history.goBack()}
      />
    </div>
  )
}

export default EditBasicInfoPage
