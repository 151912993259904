/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button } from '@material/react-button'
import { useState, FormEvent } from 'react'
import TextField, { Input } from '@material/react-text-field'
import { useTranslation } from 'react-i18next'
import { ProfilePicture } from '../../../hooks/useProfilePictures'
import IconMenu from '../../../components/IconMenu'
import { MenuListItem, MenuListItemText } from '@material/react-menu'
import EditHeadline from '../../../components/EditHeadline'
import MaterialIcon from '@material/react-material-icon'

const ProfilePictureEdit = ({
  picture,
  onDelete,
  onTitleChange,
  onCancel,
}: {
  picture: ProfilePicture
  onDelete: () => void
  onTitleChange: (newTitle: string) => void
  onCancel: () => void
}) => {
  const [title, setTitle] = useState(picture.title)
  const { t } = useTranslation()

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          max-width: calc(100% - 3rem);
        `}
      >
        <EditHeadline>{title || ''}</EditHeadline>
      </div>
      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
      >
        <IconMenu>
          <MenuListItem onClick={onDelete}>
            <MenuListItemText
              primaryText={t('profile.pictures.delete_picture')}
            />
          </MenuListItem>
        </IconMenu>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          & > div {
            width: 50%;
          }
          @media (max-width: 50rem) {
            & > div {
              width: 100%;
              margin-bottom: 1rem;
            }
          }
        `}
      >
        <div>
          <TextField outlined label={t('profile.pictures.picture_title_label')}>
            <Input
              value={title}
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setTitle(e.currentTarget.value)
              }
            />
          </TextField>
        </div>
        <div>
          <img
            css={css`
              width: 100%;
            `}
            src={picture.image}
            alt={picture.title}
          />
        </div>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;
        `}
      >
        <Button
          raised
          onClick={() => {
            onTitleChange(title)
          }}
          icon={<MaterialIcon icon="save"></MaterialIcon>}
        >
          {t('button.save')}
        </Button>
        <Button onClick={onCancel}>
          <span
            css={css`
              color: var(--theme-text);
            `}
          >
            {t('button.cancel')}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default ProfilePictureEdit
