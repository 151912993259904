import React from 'react'

export interface SnackInfo {
  message: string
  key: string
  durationMs?: number
}

export interface SnackContextValue {
  snacks?: SnackInfo[]
  showSnack?: (snack: SnackInfo) => void
  removeSnack?: (key: string) => void
}

const SnackContext = React.createContext<SnackContextValue>({})

export default SnackContext
