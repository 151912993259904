/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CVCategory } from '../../hooks/useCVCategories'
import { ListDivider, ListItem } from '@material/react-list'
import useCVEntries from '../../hooks/useCVEntries'
import { Caption } from '@material/react-typography'
import CVEntryListItem from '../../components/CVEntryListItem'

const CategoryList = ({
  category,
  selectedEntries,
}: {
  category: CVCategory
  selectedEntries: string[]
}) => {
  const { entries } = useCVEntries(category.id)
  const entriesToDisplay = entries.filter(
    entry => selectedEntries.indexOf(entry.id) > -1
  )

  if (entriesToDisplay.length === 0) return null

  return (
    <div
      css={css`
        margin-top: 0.5rem;
      `}
    >
      <Caption>{category.title}</Caption>
      {entriesToDisplay.map(entry => (
        <ListItem key={entry.id}>
          <CVEntryListItem entry={entry} />
        </ListItem>
      ))}
      <ListDivider tag="div" />
    </div>
  )
}

export default CategoryList
