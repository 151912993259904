/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Drawer, {
  DrawerContent,
  DrawerAppContent,
  DrawerHeader,
  DrawerTitle,
} from '@material/react-drawer'
import Header from './Header'
import { TopAppBarFixedAdjust } from '@material/react-top-app-bar'
import List from '@material/react-list'
import AppModule from './modules/app/App.module'
import NavigationListItem from './components/NavigationListItem'
import useBreakpoints from './hooks/useBreakpoints'
import useAccount from './hooks/useAccount'
import SnackDisplay from './modules/snacks/SnackDisplay'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useDarkmode from './modules/darkmode/useDarkmode'

const NavigationDrawer = () => {
  const { isMobile } = useBreakpoints()
  const { isLoggedIn } = useAccount()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { isDarkmode } = useDarkmode()

  useEffect(() => {
    const appContent = document.querySelector('.app-content')
    appContent?.scrollTo(0, 0)
  }, [pathname])

  const drawerIsOpen = !isMobile && isLoggedIn

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        height: 100vh;
        overflow: hidden;
        & .selected {
          background-color: var(--theme-primary);
        }
      `}
    >
      <Header />

      <TopAppBarFixedAdjust
        css={css`
          display: flex;
          width: 100%;
          position: relative;
          padding-top: 48px;
        `}
      >
        <Drawer
          css={css`
            background-color: var(--theme-background);
            border-color: rgba(var(--theme-primary-rgb), 0.45);
            ${isDarkmode
              ? css`
                  border-top: 1px solid rgba(var(--theme-primary-rgb), 0.45);
                `
              : ''}
            @media (max-width: 70rem) {
              width: 10rem;
            }
          `}
          dismissible
          open={drawerIsOpen}
        >
          <DrawerHeader>
            <DrawerTitle>
              <span
                css={css`
                  color: var(--theme-text);
                `}
              >
                Navigation
              </span>
            </DrawerTitle>
          </DrawerHeader>

          {/* Navigation links  */}
          <DrawerContent>
            <List tag="nav">
              <NavigationListItem to="/" exact label={t('pages.home')} />

              <NavigationListItem to="/entries" label={t('pages.entries')} />

              <NavigationListItem to="/resumes" label={t('pages.resumes')} />

              <NavigationListItem to="/profile" label={t('pages.profile')} />

              <div
                css={css`
                  position: absolute;
                  bottom: 3.5rem;
                  width: 100%;
                `}
              >
                <NavigationListItem to="/account" label="Account" />
              </div>
            </List>
          </DrawerContent>
        </Drawer>

        <DrawerAppContent
          className="app-content"
          css={css`
            flex: auto;
            overflow: auto;
            padding: 0 1rem;
            padding-bottom: 1rem;
            /* Min width from playing around with the drawer. */
            @media (min-width: 500px) and (max-width: 70rem) {
              margin-left: ${drawerIsOpen ? '10rem' : '0'} !important;
            }
          `}
          tag="main"
        >
          <AppModule />
        </DrawerAppContent>
      </TopAppBarFixedAdjust>
      <SnackDisplay />
    </div>
  )
}

export default NavigationDrawer
