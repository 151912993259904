/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, FormEvent } from 'react'
import { Headline4 } from '@material/react-typography'
import TextField, { Input } from '@material/react-text-field'
import IconButton from '@material/react-icon-button'
import MaterialIcon from '@material/react-material-icon'

const EditableTitle = ({
  title: initialTitle,
  onTitleChange,
  label,
}: {
  title: string
  onTitleChange: (newTitle: string) => void
  label: string
}) => {
  const [title, setTitle] = useState(initialTitle)
  const [isEditing, setIsEditing] = useState(false)

  const saveTitle = () => {
    onTitleChange(title)
    setIsEditing(false)
  }

  if (isEditing)
    return (
      <Headline4
        css={css`
          margin: 0;
        `}
      >
        <form
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          `}
          onSubmit={e => {
            e.preventDefault()
            saveTitle()
          }}
        >
          <TextField
            css={css`
              flex-grow: 1;
            `}
            label={label}
          >
            <Input
              value={title}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setTitle(e.currentTarget.value)
              }}
            />
          </TextField>

          <IconButton
            css={css`
              margin-left: 0.5rem;
            `}
            type="submit"
          >
            <MaterialIcon icon="save" />
          </IconButton>
        </form>
      </Headline4>
    )

  return (
    <Headline4
      css={css`
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      `}
    >
      {title}
      <IconButton onClick={() => setIsEditing(true)}>
        <MaterialIcon icon="edit" />
      </IconButton>
    </Headline4>
  )
}

export default EditableTitle
