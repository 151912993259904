/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import TextField, { Input, HelperText } from '@material/react-text-field'
import { FormEvent } from 'react'

const CVEntryEditTextField = ({
  value,
  onChange,
  label,
  help,
  textarea = false,
}: {
  value: any
  onChange: (value: any) => void
  label: string
  help?: string
  textarea?: boolean
}) => (
  <div
    css={css`
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
    `}
  >
    <TextField
      outlined
      textarea={textarea}
      label={label}
      helperText={<HelperText>{help}</HelperText>}
    >
      <Input
        value={value}
        onChange={(e: FormEvent<HTMLInputElement>) =>
          onChange(e.currentTarget.value)
        }
      />
    </TextField>
  </div>
)

export default CVEntryEditTextField
