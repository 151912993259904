import useAccount from './useAccount'
import useFireDocument from './useFireDocument'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import { setProfile } from '../modules/profile/Profile.slice'

export interface Profile {
  firstname?: string
  lastname?: string
  address?: {
    city?: string
    street?: string
    zip?: number
  }
  phone?: string
  mail?: string
  website?: string
  id: string
}

const useProfile = () => {
  const { uid } = useAccount()
  const { profile, isLoaded } = useSelector((state: RootState) => state.profile)
  const dispatch = useDispatch()

  const profileDocumentPath = `/users/${uid}`

  const { updateDocument: updateProfile } = useFireDocument<Profile>(
    profileDocumentPath,
    (profile: Profile) => {
      dispatch(setProfile({ profile }))
    }
  )

  return { profile, updateProfile, profileIsLoaded: isLoaded }
}

export default useProfile
