/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import { Resume } from '../../hooks/useResumes'
import Card, {
  CardActions,
  CardActionButtons,
  CardActionIcons,
} from '@material/react-card'
import { ChipSet, Chip } from '@material/react-chips'
import { Button } from '@material/react-button'
import List from '@material/react-list'
import { useTranslation } from 'react-i18next'
import useResumeGeneration from '../../hooks/useResumeGeneration'
import useProfilePictures from '../../hooks/useProfilePictures'
import { Caption, Headline4 } from '@material/react-typography'
import CategoryList from './CategoryList'
import IconMenu from '../../components/IconMenu'
import {
  MenuListItem,
  MenuListItemText,
  MenuListDivider,
} from '@material/react-menu'
import MaterialIcon from '@material/react-material-icon'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/rootReducer'
import DownloadButton from './DownloadButton'

const ResumeComponent = ({
  resume,
  onEdit,
  onDelete,
}: {
  resume: Resume
  onEdit: () => void
  onDelete: (id: string) => void
}) => {
  const categories = useSelector((state: RootState) => state.cvCategories)
  const { pictures } = useProfilePictures()
  const { downloadPDF, isLoading } = useResumeGeneration()
  const { t } = useTranslation()
  const picture =
    resume.selectedPicture !== '' &&
    pictures.filter(picture => picture.id === resume.selectedPicture)[0]

  return (
    <Card
      css={css`
        padding: 1rem;
        margin-bottom: 2rem;
        position: relative;
      `}
    >
      <Headline4
        css={css`
          margin: 0;
          max-width: calc(100% - 3rem);
        `}
      >
        {resume.title || ' '}
      </Headline4>

      <List twoLine>
        {categories.map(category => (
          <CategoryList
            category={category}
            key={category.id}
            selectedEntries={resume.selectedEntries || []}
          />
        ))}
      </List>

      {picture && (
        <Fragment>
          <Caption>{t('resumes.display.subheadline_picture')}</Caption>
          <ChipSet>
            <Chip
              id={picture.id}
              label={picture.title}
              key={picture.id}
              leadingIcon={
                <img
                  alt={picture.title}
                  src={picture.chipImage || picture.image}
                  onError={(event: any) => (event.target.src = picture.image)}
                />
              }
              disabled
            />
          </ChipSet>
        </Fragment>
      )}

      <CardActions>
        <CardActionButtons>
          <DownloadButton
            isLoading={isLoading}
            onClick={() => downloadPDF(resume)}
          />

          <Button className="color_text" onClick={onEdit}>
            {t('button.edit')}
          </Button>
        </CardActionButtons>

        <CardActionIcons>
          <IconMenu
            ButtonElement={
              <Button
                trailingIcon={
                  <MaterialIcon
                    css={css`
                      color: var(--theme-text);
                    `}
                    icon="more_vert"
                  />
                }
              >
                <span
                  css={css`
                    color: var(--theme-text);
                  `}
                >
                  {t('button.more')}
                </span>
              </Button>
            }
          >
            <MenuListItem onClick={onEdit}>
              <MenuListItemText primaryText={t('button.edit')} />
            </MenuListItem>
            <MenuListDivider />
            <MenuListItem onClick={() => onDelete(resume.id)}>
              <MenuListItemText primaryText={`Delete "${resume.title}"`} />
            </MenuListItem>
          </IconMenu>
        </CardActionIcons>
      </CardActions>
    </Card>
  )
}
export default ResumeComponent
