/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import { useState } from 'react'

const waveAnimation = keyframes`
  10% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const WavingHand = () => {
  const [waving, setWaving] = useState(false)

  const triggerWave = () => {
    setWaving(true)
    setTimeout(() => {
      setWaving(false)
    }, 2100)
  }

  return (
    <span
      onMouseEnter={triggerWave}
      css={css`
        display: inline-block;
        ${waving &&
          css`
            animation: ${waveAnimation} 2s ease-out forwards;
          `}
      `}
      role="img"
      aria-label="waving hand emoji"
    >
      👋
    </span>
  )
}

export default WavingHand
