/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Resume } from '../../../hooks/useResumes'
import { ChipSet, Chip } from '@material/react-chips'
import { Button } from '@material/react-button'
import MaterialIcon from '@material/react-material-icon'
import List from '@material/react-list'
import CVCategorySublist from './CVCategorySublist'
import useResumeGeneration from '../../../hooks/useResumeGeneration'
import useProfilePictures from '../../../hooks/useProfilePictures'
import { Caption, Headline3 } from '@material/react-typography'
import { Fragment, FormEvent, useReducer } from 'react'
import TextField, { Input } from '@material/react-text-field'
import { useTranslation } from 'react-i18next'
import useCVCategories from '../../../hooks/useCVCategories'

interface State {
  resume: Resume
  edited: boolean
}

interface Action {
  type: 'update'
  resume: Partial<Resume>
}

const resumeReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update':
      return { resume: { ...state.resume, ...action.resume }, edited: true }
    default:
      return state
  }
}

const ResumeForm = ({
  resume: initialResume,
  onUpdate,
  onCancel,
}: {
  resume: Resume
  onUpdate: (update: Resume) => void
  onCancel: () => void
}) => {
  const { cvCategories } = useCVCategories()
  const { pictures } = useProfilePictures()
  const { downloadPDF, isLoading } = useResumeGeneration()
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(resumeReducer, {
    resume: initialResume,
    edited: false,
  })

  const { resume, edited } = state

  const updateResume = (resume: Partial<Resume>) =>
    dispatch({ type: 'update', resume })

  const onTitleChange = (title: string) => updateResume({ title })

  const onEntryToggle = (entryId: string) => {
    const isSelected = resume.selectedEntries
      ? resume.selectedEntries.indexOf(entryId) > -1
      : false
    if (isSelected) {
      updateResume({
        selectedEntries: resume.selectedEntries.filter(
          selectedId => selectedId !== entryId
        ),
      })
    } else {
      updateResume({
        selectedEntries: resume.selectedEntries
          ? resume.selectedEntries.concat([entryId])
          : [entryId],
      })
    }
  }

  const onPictureSelect = (pictureId: string) => {
    // Due to how ChipSets work and the update event this is fired once at
    // initial render. We catch that case here.
    if (pictureId === resume.selectedPicture) return
    updateResume({ selectedPicture: pictureId })
  }

  return (
    <Fragment>
      <Headline3
        css={css`
          margin-bottom: 0;
          margin-top: 1rem;
          max-width: calc(100% - 3rem);
        `}
      >
        {resume.title || ' '}
      </Headline3>

      <div
        css={css`
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
        `}
      >
        <TextField outlined label={t('resumes.title_label')}>
          <Input
            value={resume.title}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              onTitleChange(e.currentTarget.value)
            }
          />
        </TextField>
      </div>

      <List twoLine>
        {cvCategories.map(category => (
          <CVCategorySublist
            category={category}
            key={category.id}
            onEntryToggle={onEntryToggle}
            selectedEntries={resume.selectedEntries || []}
          />
        ))}
      </List>

      <Caption>Picture</Caption>
      <ChipSet
        choice
        selectedChipIds={
          resume.selectedPicture ? [resume.selectedPicture] : undefined
        }
      >
        {pictures.map(picture => (
          <Chip
            handleSelect={(pictureId: string, selected: boolean) =>
              selected && onPictureSelect(pictureId)
            }
            id={picture.id}
            label={picture.title}
            key={picture.id}
            leadingIcon={
              <img
                alt={picture.title}
                src={picture.chipImage || picture.image}
                onError={(event: any) => (event.target.src = picture.image)}
              />
            }
          />
        ))}
      </ChipSet>

      <div
        css={css`
          margin-top: 2rem;
        `}
      >
        <Button
          onClick={() => onUpdate(resume)}
          raised
          disabled={!edited}
          icon={<MaterialIcon icon="save"></MaterialIcon>}
        >
          Save
        </Button>

        <Button
          css={css`
            margin-left: 1rem;
          `}
          disabled={isLoading}
          onClick={() => downloadPDF(resume)}
        >
          {isLoading ? t('resumes.get_PDF_loading') : t('resumes.get_PDF')}
        </Button>

        <Button
          css={css`
            float: right;
          `}
          className="color_text"
          onClick={onCancel}
        >
          {edited ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Fragment>
  )
}
export default ResumeForm
