/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import useDarkmode from './useDarkmode'

const DarkmodeButton = () => {
  const { isDarkmode, toggleDarkmode } = useDarkmode()
  return (
    <div
      css={css`
        background-color: var(--theme-surface-background);
        border-radius: 50%;
        height: 1.66rem;
        width: 1.66rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        border: 1px solid var(--theme-accent);
        opacity: 0.66;
        &:hover {
          opacity: 1;
        }
      `}
      onClick={toggleDarkmode}
    >
      <span>{isDarkmode ? '🌙' : '☀️'}</span>
    </div>
  )
}

export default DarkmodeButton
