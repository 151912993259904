import React, { useState } from 'react'
import SnackContext, { SnackInfo } from './snackContext'

const SnackContextProvider = ({ children }: any) => {
  const [snacks, setSnacks] = useState<SnackInfo[]>([])

  const showSnack = (snack: SnackInfo) => setSnacks(snacks.concat([snack]))

  const removeSnack = (key: string) => {
    setSnacks(snacks.filter(snack => snack.key !== key))
  }

  const value = { snacks, showSnack, removeSnack }

  return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>
}

export default SnackContextProvider
