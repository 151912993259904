import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CVCategory } from '../../hooks/useCVCategories'

interface setCategoriesPayload {
  cvCategories: CVCategory[]
}

const initialState: CVCategory[] = []

const CVCategorySlice = createSlice({
  name: 'cvCategory',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<setCategoriesPayload>) {
      return action.payload.cvCategories
    },
  },
})

export const { setCategories } = CVCategorySlice.actions

export default CVCategorySlice.reducer
