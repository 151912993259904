import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

// import i18n (needs to be bundled 📦)
import './i18n'
import store from './redux/store'
import DarkmodeContextProvider from './modules/darkmode/darkmodeContextProvider'

// Initialize error tracking, but only for builds.
if (process.env.NODE_ENV === 'production') {
  const hawk = require('hawk.javascript')
  hawk.init({ token: '22597109-1954-438f-9b9f-665f9e2f0265' })
}

const render = () => {
  const App = require('./App').default

  ReactDOM.render(
    <Provider store={store}>
      <DarkmodeContextProvider>
        <App />
      </DarkmodeContextProvider>
    </Provider>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
