import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Resume } from '../../hooks/useResumes'

const resumeSlice = createSlice({
  name: 'resume',
  initialState: [] as Resume[],
  reducers: {
    setResumes(state, action: PayloadAction<{ resumes: Resume[] }>) {
      return action.payload.resumes
    },
  },
})

export const { setResumes } = resumeSlice.actions

export default resumeSlice.reducer
