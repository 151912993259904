/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Headline2, Headline3 } from '@material/react-typography'
import { ReactElement } from 'react'
import useBreakpoints from '../hooks/useBreakpoints'

const PageHeadline = ({
  children,
}: {
  children: ReactElement | ReactElement[] | string
}) => {
  const { isMobile } = useBreakpoints()

  // On mobile we use a smaller font size, to fit headlines onto the page.
  if (isMobile)
    return (
      <Headline3
        css={css`
          margin-top: 1rem;
          font-weight: 300;
          /* To make sure headlines to make pages side-scrollable, we break them. */
          word-break: break-all;
        `}
      >
        {children}
      </Headline3>
    )

  return (
    <Headline2
      css={css`
        margin-top: 2rem;
      `}
    >
      {children}
    </Headline2>
  )
}
export default PageHeadline
