/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ListItem, ListItemText } from '@material/react-list'
import { useHistory, useLocation } from 'react-router'

const NavigationListItem = ({
  to,
  label,
  exact,
}: {
  to: string
  label: string
  exact?: boolean
}) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const isHighlighted = exact ? pathname === to : pathname.startsWith(to)

  return (
    <ListItem
      css={css`
        ${isHighlighted
          ? css`
              background-color: var(--theme-highlight);
            `
          : ''}
      `}
      onClick={() => history.push(to)}
    >
      <ListItemText
        css={css`
          ${isHighlighted
            ? css`
                color: var(--theme-on-primary);
              `
            : css`
                color: var(--theme-text);
              `}
        `}
        primaryText={label}
      />
    </ListItem>
  )
}

export default NavigationListItem
