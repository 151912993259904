import React from 'react'
import { RouteComponentProps, Route } from 'react-router'
import Wrapper from '../../components/Wrapper'
import Profile from './Profile'
import EditProfilePicturePage from './pictures/EditProfilePicturePage'
import EditBasicInfoPage from './basicInfo/EditBasicInfoPage'

const ProfileModule = ({ match }: RouteComponentProps) => {
  return (
    <Wrapper>
      <Route path={match.path} exact component={Profile}></Route>
      <Route
        path={`${match.path}/pictures/:pictureId`}
        component={EditProfilePicturePage}
      ></Route>
      <Route
        path={`${match.path}/basicInfo`}
        component={EditBasicInfoPage}
      ></Route>
    </Wrapper>
  )
}

export default ProfileModule
