import firebase from '../firebase'
import { useState } from 'react'
import { CVCategory } from './useCVCategories'
import useAccount from './useAccount'
import { Resume } from './useResumes'
import { Profile } from './useProfile'
import useProfilePictures from './useProfilePictures'
import useSnacks from '../modules/snacks/useSnacks'
import { useTranslation } from 'react-i18next'
import { CVEntry } from './useCVEntry'

const saveFile = (dataUrl: any, fileName: any) => {
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)
  downloadLink.href = dataUrl
  downloadLink.download = fileName
  downloadLink.click()
  window.URL.revokeObjectURL(dataUrl)
}

const useResumeGeneration = () => {
  const db = firebase.firestore()
  const { uid } = useAccount()
  const { pictures } = useProfilePictures()
  const [isLoading, setIsLoading] = useState(false)
  const { showSnack } = useSnacks()
  const { t } = useTranslation()

  const cvCategoryCollectionPath = `users/${uid}/cvCategories`

  const downloadPDF = async (resume: Resume) => {
    setIsLoading(true)

    // Get ALL the things
    try {
      const categories = (await db
        .collection(cvCategoryCollectionPath)
        .get()
        .then(snapshot =>
          snapshot.docs.map(document => ({
            ...document.data(),
            id: document.id,
          }))
        )) as CVCategory[]
      const entries = ((await db
        .collection(`/users/${uid}/cvEntries`)
        .get()
        .then(snapshot =>
          snapshot.docs.map(document => ({
            ...document.data(),
            id: document.id,
          }))
        )) as CVEntry[])
        .filter(
          // Only take entries that are in the final resume.
          entry => resume.selectedEntries.indexOf(entry.id) > -1
        )
        .map(entry => ({
          // This map is to fill data for the backend. Soon to be obsolete.
          ...entry,
          current: entry.isOngoing,
          institute: entry.company,
        }))
        .sort((a, b) => {
          if (a.isOngoing) return -1
          if (b.isOngoing) return 1
          if (a.end && b.end && a.end.year !== b.end.year)
            return b.end.year - a.end.year
          if (a.end && b.end) return b.end.month - a.end.month
          return 0
        })

      const profile = (await db
        .doc(`/users/${uid}`)
        .get()
        .then(snapshot => ({ ...snapshot.data(), id: snapshot.id }))) as Profile

      // Build the request
      const populatedCategories = categories
        .map(category => ({
          ...category,
          entries: entries.filter(entry => entry.categoryId === category.id),
        }))
        // Now only take categories that actually have selected entries for this resume.
        // As entries are already filtered to be within the resume.
        .filter(category => category.entries.length > 0)

      const portraitUrl = pictures.reduce((url, picture) => {
        if (picture.id === resume.selectedPicture) return picture.image
        return url
      }, '')

      //Some value mapping for old backend
      const userForBackend = {
        ...profile,
        ...profile.address,
        mobilephone: profile.phone,
        email: profile.mail,
      }

      const requestData = {
        user: userForBackend,
        cv: { categories: populatedCategories, portraitUrl },
      }

      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(requestData),
        mode: 'cors',
        headers: new window.Headers({
          'Content-Type': 'application/json',
        }),
      } as RequestInit

      const showSnackTimeout = setTimeout(() => {
        showSnack(t('resumes.warmup_notice'))
      }, 2000)
      const stopSnack = () => clearTimeout(showSnackTimeout)

      fetch('https://pdf.movi.rocks/generate/cv', fetchOptions)
        .then(response => {
          stopSnack()
          if (!response.ok) throw new Error('Network response was not ok.')
          return response.blob()
        })
        .then(blob => window.URL.createObjectURL(blob))
        .then(url => {
          saveFile(
            url,
            `${t('general.resume')}-${profile.firstname}-${Date.now()}.pdf`
          )
          setIsLoading(false)
        })
        .catch(() => {
          stopSnack()
        })
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  return { isLoading, downloadPDF }
}

export default useResumeGeneration
