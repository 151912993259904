/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import { Fragment } from 'react'
import { Button } from '@material/react-button'
import { useTranslation } from 'react-i18next'

const blink = keyframes`
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
`

const DownloadButton = ({
  onClick,
  isLoading,
}: {
  isLoading: boolean
  onClick: () => void
}) => {
  const { t } = useTranslation()

  return (
    <Button
      disabled={isLoading}
      onClick={onClick}
      css={css`
        & .dot {
          animation: ${blink} 1.4s infinite;
          animation-fill-mode: both;
        }
        & span:nth-of-type(2) {
          animation-delay: 0.2s;
        }
        & span:nth-of-type(3) {
          animation-delay: 0.4s;
        }
      `}
    >
      {isLoading ? (
        <Fragment>
          {t('resumes.get_PDF_loading')}
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </Fragment>
      ) : (
        t('resumes.get_PDF')
      )}
    </Button>
  )
}

export default DownloadButton
