import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import useAccount from './useAccount'
import firebase from '../firebase'
import { Profile } from './useProfile'
import useRegistrations from './useRegistrations'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import { setProfilePictures } from '../modules/profile/profilePicture.slice'

const capitalize = (string: string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export interface ProfilePicture {
  id: string
  image: string
  refPath: string
  title: string
  chipImage?: string
  dimensions: {
    width: number
    height: number
  }
}

const db = firebase.firestore()
const storage = firebase.storage()

const useProfilePictures = () => {
  const { uid, isLoggedIn } = useAccount()
  const [isUploading, setIsUploading] = useState(false)
  const { isRegistered, addRegistration } = useRegistrations()
  const dispatch = useDispatch()
  const pictures = useSelector((state: RootState) => state.profilePictures)

  const profilePircturesCollectionPath = `users/${uid}/profilePictures`

  useEffect(() => {
    if (!isLoggedIn) return
    if (isRegistered(profilePircturesCollectionPath)) return
    addRegistration(profilePircturesCollectionPath)
    db.collection(profilePircturesCollectionPath).onSnapshot(snapshot => {
      const pictures = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })) as ProfilePicture[]

      dispatch(setProfilePictures({ pictures }))
    })
  }, [
    isLoggedIn,
    profilePircturesCollectionPath,
    addRegistration,
    dispatch,
    isRegistered,
  ])

  const addPicture = (newPicture: Partial<Profile>) => {
    db.collection(profilePircturesCollectionPath)
      .doc(newPicture.id)
      .set(newPicture)
  }

  const deletePicture = async (pictureId: string) => {
    // Just delete the reference to the image. A cloud function takes care of cleanup of the files.
    db.collection(profilePircturesCollectionPath)
      .doc(pictureId)
      .delete()
  }

  const uploadPicture = async (file: File) => {
    // Upload one picture at a time.
    if (isUploading) return
    setIsUploading(true)
    const id = uuidv4()

    // Create a path where to save the image, taking it's extension from the files names.
    const refPath = `/users/${uid}/profile-pictures/${id}${file.name.replace(
      /^.*\./,
      '.'
    )}`
    const imageReference = storage.ref(refPath)

    try {
      const metadata = {
        size: file.size,
        contentType: file.type,
      }
      const snapshot = await imageReference.put(file, metadata)
      if (snapshot.state !== 'success') throw Error('UPLOAD unsuccessful')
      let image = await imageReference.getDownloadURL()

      // Initial title = files name without extension.
      const title = capitalize(file.name.replace(/\.\w*$/, ''))
      const fileUrl = URL.createObjectURL(file)
      const dimensions = await new Promise(resolve => {
        const img = new Image()
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          })
        }
        img.src = fileUrl
      })
      const profilePicture = {
        id,
        image,
        title,
        refPath,
        dimensions,
      } as ProfilePicture
      addPicture(profilePicture)
      setIsUploading(false)
    } catch (e) {
      setIsUploading(false)
      console.warn('Upload error', e)
    }
  }

  const updatePicture = (
    pictureId: string,
    update: Partial<ProfilePicture>
  ) => {
    db.doc(`${profilePircturesCollectionPath}/${pictureId}`).update(update)
  }

  return { pictures, uploadPicture, deletePicture, isUploading, updatePicture }
}

export default useProfilePictures
