/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import useResumes from '../../hooks/useResumes'
import List from '@material/react-list'
import { Button } from '@material/react-button'
import PageHeadline from '../../components/PageHeadline'
import ResumeComponent from './ResumeComponent'
import { RouteComponentProps } from 'react-router'

const ResumesList = ({ history }: RouteComponentProps) => {
  const { resumes, createResume, deleteResume } = useResumes()
  const { t } = useTranslation()

  return (
    <Fragment>
      <PageHeadline>{t('resumes.headline_resumes')}</PageHeadline>

      <List>
        {resumes.map(resume => (
          <ResumeComponent
            resume={resume}
            key={resume.id}
            onDelete={() => deleteResume(resume.id)}
            onEdit={() => history.push(`/resumes/${resume.id}`)}
          />
        ))}
      </List>

      <Button
        css={css`
          margin-bottom: 1rem;
        `}
        onClick={createResume}
        raised={resumes.length === 0}
      >
        {t('resumes.add_resume')}
      </Button>
    </Fragment>
  )
}

export default ResumesList
