import React from 'react'
import { Route, RouteComponentProps } from 'react-router'
import ResumesList from './ResumesList'
import Wrapper from '../../components/Wrapper'
import EditResumePage from './edit/EditResume.page'

const ResumesModule = ({ match }: RouteComponentProps) => {
  return (
    <Wrapper>
      <Route path={match.path} exact component={ResumesList}></Route>
      <Route
        path={`${match.path}/:resumeId`}
        exact
        component={EditResumePage}
      ></Route>
    </Wrapper>
  )
}

export default ResumesModule
