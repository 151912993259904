import { useEffect } from 'react'
import firebase from '../firebase'
import useAccount from './useAccount'
import useRegistrations from './useRegistrations'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import { setResumes } from '../modules/resumes/resume.slice'

export interface Resume {
  id: string
  title: string
  selectedEntries: string[]
  selectedPicture?: string
}

const useCVCategories = () => {
  const { uid, isLoggedIn } = useAccount()
  const db = firebase.firestore()
  const resumes = useSelector((state: RootState) => state.resumes)
  const { isRegistered, addRegistration } = useRegistrations()
  const dispatch = useDispatch()

  const resumesCollectionPath = `users/${uid}/resumes`

  useEffect(() => {
    if (!isLoggedIn) return
    if (isRegistered(resumesCollectionPath)) return
    addRegistration(resumesCollectionPath)
    db.collection(resumesCollectionPath).onSnapshot(snapshot => {
      const resumes = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })) as Resume[]
      dispatch(setResumes({ resumes }))
    })
  }, [
    db,
    isLoggedIn,
    resumesCollectionPath,
    isRegistered,
    addRegistration,
    dispatch,
  ])

  const createResume = () => {
    db.collection(resumesCollectionPath).add({
      title: 'New Resume',
    })
  }

  const deleteResume = (id: string) => {
    db.collection(resumesCollectionPath)
      .doc(id)
      .delete()
  }

  const updateResume = (id: string, newResume: Partial<Resume>) => {
    db.collection(resumesCollectionPath)
      .doc(id)
      .update(newResume)
  }

  const selectEntryForResume = (resumeId: string, entryId: string) => {
    db.collection(resumesCollectionPath)
      .doc(resumeId)
      .update({
        selectedEntries: firebase.firestore.FieldValue.arrayUnion(entryId),
      })
  }

  const deSelectEntryForResume = (resumeId: string, entryId: string) => {
    db.collection(resumesCollectionPath)
      .doc(resumeId)
      .update({
        selectedEntries: firebase.firestore.FieldValue.arrayRemove(entryId),
      })
  }

  return {
    resumes,
    createResume,
    updateResume,
    deleteResume,
    selectEntryForResume,
    deSelectEntryForResume,
  }
}

export default useCVCategories
