/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Headline3 } from '@material/react-typography'
import Spinner from './Spinner'

const AppLoadingOverlay = () => (
  <div
    css={css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: white;
    `}
  >
    <div
      css={css`
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
      `}
    >
      <Headline3>MoVi</Headline3>
      <Spinner />
    </div>
  </div>
)

export default AppLoadingOverlay
