import { useSelector } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import useCVEntries from './useCVEntries'

export interface CVEntry {
  company: string
  id: string
  categoryId: string
  title: string
  description: string
  path: string
  start: {
    year: number
    month: number
  }
  end?: {
    year: number
    month: number
  }
  isOngoing: boolean
}

const useCVEntry = (entryId: string) => {
  const { updateEntry: collectionUpdateEntry } = useCVEntries('')

  const entry = useSelector((state: RootState) =>
    state.cvEntries.find(stateEntry => stateEntry.id === entryId)
  )

  const updateEntry = (entryUpdate: Partial<CVEntry>) =>
    collectionUpdateEntry(entryId, entryUpdate)

  const isLoaded = !!entry

  return { entry, updateEntry, isLoaded }
}

export default useCVEntry
