import useProfilePictures, { ProfilePicture } from './useProfilePictures'
import { useSelector } from 'react-redux'

import { RootState } from '../redux/rootReducer'

const useProfilePicture = (pictureId: string) => {
  const picture = useSelector((state: RootState) =>
    state.profilePictures.find(picture => picture.id === pictureId)
  )
  const {
    deletePicture: superDeletePicture,
    updatePicture: superUpdatePicture,
  } = useProfilePictures()

  const deletePicture = () => {
    superDeletePicture(pictureId)
  }

  const updatePicture = (pictureUpdate: Partial<ProfilePicture>) =>
    superUpdatePicture(pictureId, pictureUpdate)

  return { picture, updatePicture, deletePicture }
}

export default useProfilePicture
