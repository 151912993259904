/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CVEntry } from '../../hooks/useCVEntry'
import { useState } from 'react'
import { Button } from '@material/react-button'
import { Caption, Body2, Headline3 } from '@material/react-typography'
import CVEntryEditTextField from './CVEntryEditTextField'
import MonthSelect from '../../components/MonthSelect'
import IconMenu from '../../components/IconMenu'
import { MenuListItem, MenuListItemText } from '@material/react-menu'
import MaterialIcon from '@material/react-material-icon'
import { useTranslation } from 'react-i18next'

const CVEntryEdit = ({
  entry: initialEntry,
  onSave,
  onCancel,
}: {
  entry: Partial<CVEntry>
  onSave: (cvEntry: Partial<CVEntry>) => void
  onCancel: () => void
}) => {
  const [entry, setEntry] = useState(initialEntry)
  const [wasEdited, setWasEdited] = useState(false)
  const { t } = useTranslation()

  const updateEntry = (update: Partial<CVEntry>) => {
    setEntry({ ...entry, ...update })
    setWasEdited(true)
  }

  const updateField = (field: string) => (newValue: any) => {
    updateEntry({ [field]: newValue })
  }

  return (
    <form
      css={css`
        position: relative;
      `}
      onSubmit={e => e.preventDefault()}
    >
      <Headline3
        css={css`
          margin-bottom: 0;
          margin-top: 1rem;
          max-width: calc(100% - 3rem);
        `}
      >
        {entry.title || '.'}
      </Headline3>
      <Body2>{entry.company}</Body2>

      <div
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
      >
        <IconMenu>
          <MenuListItem>
            <MenuListItemText primaryText={t('entry.delete_entry')} />
          </MenuListItem>
        </IconMenu>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 2rem;
        `}
      >
        <CVEntryEditTextField
          label={t('entry.inputs.title_label')}
          help={t('entry.inputs.title_help')}
          value={entry.title}
          onChange={updateField('title')}
        />

        <CVEntryEditTextField
          label={t('entry.inputs.company_label')}
          help={t('entry.inputs.company_help')}
          value={entry.company}
          onChange={updateField('company')}
        />

        <CVEntryEditTextField
          label={t('entry.inputs.description_label')}
          help={t('entry.inputs.description_help')}
          value={entry.description}
          onChange={updateField('description')}
          textarea
        />

        <Caption>{t('entry.start')}</Caption>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;
            & > div {
              width: 49%;
            }
          `}
        >
          <CVEntryEditTextField
            label={t('entry.inputs.start_year_label')}
            help={t('entry.inputs.start_year_help')}
            value={entry.start ? entry.start.year : new Date().getFullYear()}
            onChange={(newValue: string) => {
              const newYear = parseInt(newValue, 10)
              if (isNaN(newYear))
                return console.warn('newYear is NaN', newValue)
              updateEntry({
                start: {
                  year: newYear,
                  month: entry.start ? entry.start.month : 0,
                },
              })
            }}
          />
          <MonthSelect
            label={t('entry.inputs.start_month_label')}
            outlined
            value={entry.start ? entry.start.month.toString() : '1'}
            onChange={newValue =>
              updateEntry({
                start: {
                  year: entry.start
                    ? entry.start.year
                    : new Date().getFullYear(),
                  month: parseInt(newValue, 10),
                },
              })
            }
          />
        </div>

        <Caption>
          {t('entry.end')} {entry.isOngoing && `(${t('entry.ongoing')})`}
        </Caption>

        {!entry.isOngoing && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 1rem;
              & > div {
                width: 49%;
              }
            `}
          >
            <CVEntryEditTextField
              label={t('entry.inputs.end_year_label')}
              help={t('entry.inputs.end_year_help')}
              value={entry.end ? entry.end.year : new Date().getFullYear()}
              onChange={(newValue: string) => {
                const newYear = parseInt(newValue, 10)
                if (isNaN(newYear))
                  return console.warn('newYear is NaN', newValue)
                updateEntry({
                  end: {
                    year: newYear,
                    month: entry.end ? entry.end.month : 0,
                  },
                })
              }}
            />
            <MonthSelect
              label={t('entry.inputs.end_month_label')}
              outlined
              value={
                entry.end
                  ? entry.end.month.toString()
                  : (new Date().getMonth() + 1).toString()
              }
              onChange={newValue =>
                updateEntry({
                  end: {
                    year: entry.end ? entry.end.year : new Date().getFullYear(),
                    month: parseInt(newValue, 10),
                  },
                })
              }
            />
          </div>
        )}
        <Button
          onClick={() => {
            updateEntry({
              isOngoing: !entry.isOngoing,
            })
          }}
        >
          {entry.isOngoing ? 'Set end date' : 'Set to ongoing'}
        </Button>
      </div>

      <div
        css={css`
          margin-top: 2rem;
        `}
      >
        <Button
          raised
          disabled={!wasEdited}
          onClick={() => onSave(entry)}
          icon={<MaterialIcon icon="save"></MaterialIcon>}
        >
          {t('button.save')}
        </Button>

        <Button
          css={css`
            float: right;
          `}
          onClick={() => onCancel()}
          className="color_text"
        >
          {t('button.cancel')}
        </Button>
      </div>
    </form>
  )
}

export default CVEntryEdit
