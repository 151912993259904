/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CVCategory } from '../../../hooks/useCVCategories'
import { ListDivider } from '@material/react-list'
import useCVEntries from '../../../hooks/useCVEntries'
import { Caption } from '@material/react-typography'
import CVEntryListItem from '../../../components/CVEntryListItem'
import Checkbox from '@material/react-checkbox'

const CVCategorySublist = ({
  category,
  onEntryToggle,
  selectedEntries,
}: {
  category: CVCategory
  onEntryToggle: (entryId: string) => void
  selectedEntries: string[]
}) => {
  const { entries } = useCVEntries(category.id)
  return (
    <div
      css={css`
        margin-top: 0.5rem;
      `}
    >
      <Caption>{category.title}</Caption>
      {entries.map(entry => (
        <div
          key={entry.id}
          onClick={() => onEntryToggle(entry.id)}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Checkbox checked={selectedEntries.includes(entry.id)} />

          <CVEntryListItem entry={entry} />
        </div>
      ))}
      <ListDivider
        tag="div"
        css={css`
          margin-top: 1rem;
        `}
      />
    </div>
  )
}

export default CVCategorySublist
