declare global {
  interface Window {
    moviRegistrations?: string[]
  }
}

const useRegistrations = () => {
  if (!window.moviRegistrations) window.moviRegistrations = []

  const addRegistration = (registrationIdentifier: string) => {
    if (window.moviRegistrations?.includes(registrationIdentifier)) return
    window.moviRegistrations?.push(registrationIdentifier)
  }

  const isRegistered = (registrationIdentifier: string) => {
    return window.moviRegistrations?.includes(registrationIdentifier)
  }

  return { addRegistration, isRegistered }
}

export default useRegistrations
