/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import { Profile } from '../../../hooks/useProfile'
import { Caption } from '@material/react-typography'
import TextField, { Input } from '@material/react-text-field'
import MaterialIcon from '@material/react-material-icon'
import { CardActionButtons, CardActionIcons } from '@material/react-card'
import { useState, FormEvent } from 'react'
import { Button } from '@material/react-button'
import { useTranslation } from 'react-i18next'

const ProfileForm = ({
  profile: initialProfile,
  onUpdate,
  onCancel,
}: {
  profile: Profile
  onUpdate: (update: Partial<Profile>) => void
  onCancel: () => void
}) => {
  const [profile, setProfile] = useState<Profile>(initialProfile)
  const { t } = useTranslation()

  const updateProfile = (update: Partial<Profile>) => {
    setProfile({ ...profile, ...update })
  }

  const updateAddress = (update: any) => {
    updateProfile({
      address: {
        ...profile.address,
        ...update,
      },
    })
  }

  return (
    <Fragment>
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
        css={css`
          display: flex;
          flex-direction: column;
          & > div {
            margin-bottom: 1rem;
          }
        `}
      >
        <TextField
          label={t('profile.basic_information.inputs.firstname_label')}
        >
          <Input
            value={profile.firstname || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateProfile({ firstname: e.currentTarget.value })
            }
          />
        </TextField>

        <TextField label={t('profile.basic_information.inputs.lastname_label')}>
          <Input
            value={profile.lastname || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateProfile({ lastname: e.currentTarget.value })
            }
          />
        </TextField>

        <Caption>
          {t('profile.basic_information.section_headline_address')}
        </Caption>

        <TextField label={t('profile.basic_information.inputs.street_label')}>
          <Input
            value={(profile.address && profile.address.street) || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateAddress({ street: e.currentTarget.value })
            }
          />
        </TextField>

        <TextField label={t('profile.basic_information.inputs.city_label')}>
          <Input
            value={(profile.address && profile.address.city) || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateAddress({ city: e.currentTarget.value })
            }
          />
        </TextField>

        <TextField label={t('profile.basic_information.inputs.zip_label')}>
          <Input
            value={(profile.address && profile.address.zip) || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateAddress({ zip: e.currentTarget.value })
            }
          />
        </TextField>

        <Caption>
          {t('profile.basic_information.section_headline_additional')}
        </Caption>

        <TextField label={t('profile.basic_information.inputs.email_label')}>
          <Input
            value={profile.mail || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateProfile({ mail: e.currentTarget.value })
            }
          />
        </TextField>

        <TextField label={t('profile.basic_information.inputs.phone_label')}>
          <Input
            value={profile.phone || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateProfile({ phone: e.currentTarget.value })
            }
          />
        </TextField>

        <TextField label={t('profile.basic_information.inputs.website_label')}>
          <Input
            value={profile.website || ''}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              updateProfile({ website: e.currentTarget.value })
            }
          />
        </TextField>
      </form>
      <div
        css={css`
          display: flex;
          margin-top: 1rem;
        `}
      >
        <CardActionButtons>
          <Button
            onClick={() => onUpdate(profile)}
            icon={<MaterialIcon icon="save"></MaterialIcon>}
            raised
          >
            {t('button.save')}
          </Button>
        </CardActionButtons>
        <CardActionIcons>
          <Button onClick={onCancel} className="color_text">
            {t('button.cancel')}
          </Button>
        </CardActionIcons>
      </div>
    </Fragment>
  )
}

export default ProfileForm
