/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, ReactElement } from 'react'
import { ListItemText, ListItemMeta } from '@material/react-list'
import { CVEntry } from '../hooks/useCVEntry'
import withLeadingZero from '../helpers/withLeadingZero'

const CVEntryListItem = ({
  entry,
  meta,
}: {
  entry: CVEntry
  meta?: ReactElement | string
}) => {
  let secondaryText = entry.company || ' '
  if (entry.start) {
    secondaryText += ` ${withLeadingZero(entry.start.month)}.${
      entry.start.year
    }`
  }
  if (entry.isOngoing) {
    secondaryText += ` - today`
  } else if (entry.end) {
    secondaryText += ` - ${withLeadingZero(entry.end.month)}.${entry.end.year}`
  }
  return (
    <Fragment>
      <ListItemText
        primaryText={entry.title || ' '}
        secondaryText={secondaryText}
      />
      {meta && <ListItemMeta meta={meta} />}
    </Fragment>
  )
}

export default CVEntryListItem
