/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Profile } from '../../hooks/useProfile'
import List, { ListItemText, ListItem } from '@material/react-list'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

const ItemIfExists = ({
  content,
  label,
}: {
  content: ReactNode
  label: string
}) => (
  <ListItem>
    <ListItemText primaryText={`${label}: ${content}`} />
  </ListItem>
)

const ProfileDisplay = ({ profile }: { profile: Profile }) => {
  const { t } = useTranslation()

  return (
    <List>
      <ItemIfExists
        content={profile.firstname || ''}
        label={t('profile.basic_information.inputs.firstname_label')}
      />
      <ItemIfExists
        content={profile.lastname || ''}
        label={t('profile.basic_information.inputs.lastname_label')}
      />
      <ItemIfExists
        content={profile.address ? profile.address.street : ''}
        label={t('profile.basic_information.inputs.street_label')}
      />
      <ItemIfExists
        content={profile.address ? profile.address.city : ''}
        label={t('profile.basic_information.inputs.city_label')}
      />
      <ItemIfExists
        content={profile.address ? profile.address.zip : ''}
        label={t('profile.basic_information.inputs.zip_label')}
      />
      <ItemIfExists
        content={profile.mail || ''}
        label={t('profile.basic_information.inputs.email_label')}
      />
      <ItemIfExists
        content={profile.phone || ''}
        label={t('profile.basic_information.inputs.phone_label')}
      />
      <ItemIfExists
        content={profile.website || ''}
        label={t('profile.basic_information.inputs.website_label')}
      />
    </List>
  )
}

export default ProfileDisplay
