import { useEffect } from 'react'
import firebase from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { login, noUserFound } from '../modules/account/account.slice'
import { RootState } from '../redux/rootReducer'
import useRegistrations from './useRegistrations'

const useAccount = () => {
  const registrationKey = 'user-registration'
  const auth = firebase.auth()
  const dispatch = useDispatch()
  const { uid, isLoggedIn, userLoaded } = useSelector(
    (state: RootState) => state.account
  )
  const { isRegistered, addRegistration } = useRegistrations()

  useEffect(() => {
    if (isRegistered(registrationKey)) return
    addRegistration(registrationKey)
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(login({ uid: user.uid }))
      } else {
        dispatch(noUserFound())
      }
    })
  }, [auth, dispatch, isRegistered, addRegistration])

  const updateUser = () => {}

  const logout = async () => {
    try {
      await firebase.auth().signOut()
    } catch (e) {
      console.error('Signout error', e)
    }
  }

  return { uid, isLoggedIn, updateUser, logout, userLoaded }
}

export default useAccount
