/** @jsx jsx */
import { jsx } from '@emotion/core'
import { RouteComponentProps } from 'react-router'
import useProfilePicture from '../../../hooks/useProfilePicture'
import ProfilePictureEdit from './ProfilePictureEdit'

interface MatchParams {
  pictureId: string
}

const EditProfilePicturePage = ({
  match,
  history,
}: RouteComponentProps<MatchParams>) => {
  const { pictureId } = match.params
  const { picture, updatePicture, deletePicture } = useProfilePicture(pictureId)

  if (!picture) return null
  return (
    <ProfilePictureEdit
      picture={picture}
      onDelete={() => {
        deletePicture()
        history.goBack()
      }}
      onTitleChange={title => {
        updatePicture({ title })
        history.goBack()
      }}
      onCancel={() => history.goBack()}
    />
  )
}

export default EditProfilePicturePage
