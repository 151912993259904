/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PageHeadline from '../../components/PageHeadline'
import Wrapper from '../../components/Wrapper'
import { Body1, Headline4 } from '@material/react-typography'
import firebase from '../../firebase'
import { Button } from '@material/react-button'
import useSnacks from '../snacks/useSnacks'
import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import useProfile from '../../hooks/useProfile'

const Account = () => {
  const { showSnack } = useSnacks()
  const { profile } = useProfile()
  const { t } = useTranslation()

  const [isDeleting, setIsDeleting] = useState(false)

  const name = profile.firstname || '🤘'

  const onDelete = () => {
    const user = firebase.auth().currentUser
    if (!user) return
    setIsDeleting(true)
    user
      .delete()
      .then(() => {
        // Force the browser to reload the home page so users get a clean slate.
        // This basically cleans up subscriptions etc.
        window.location.assign(window.location.origin)
      })
      .catch(() => {
        showSnack(t('account.deletion_failed_notice'))
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  return (
    <Wrapper>
      <PageHeadline>{t('account.headline_account')}</PageHeadline>
      <Body1>
        <Trans i18nKey="account.greeting">{{ name }}</Trans>
      </Body1>

      <Headline4
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {t('account.management_headline')}
      </Headline4>
      <Body1>{t('account.deletion_disclaimer')}</Body1>
      <Button
        disabled={isDeleting}
        className="button_text"
        outlined
        onClick={onDelete}
      >
        {t('account.button_delete_account')}
      </Button>
    </Wrapper>
  )
}

export default Account
