/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Headline3 } from '@material/react-typography'
import { ReactElement } from 'react'

const EditHeadline = ({
  children,
}: {
  children: ReactElement | ReactElement[] | string
}) => (
  <Headline3
    css={css`
      margin-top: 0.5em;
    `}
  >
    {children}
  </Headline3>
)

export default EditHeadline
