import React from 'react'
import { RouteComponentProps } from 'react-router'
import useCVEntry, { CVEntry } from '../../hooks/useCVEntry'
import CVEntryEdit from './CVEntryEdit'

interface MatchParams {
  entryId: string
}

const EditCVEntryPage = ({
  match,
  history,
}: RouteComponentProps<MatchParams>) => {
  const { entry, updateEntry } = useCVEntry(match.params.entryId)

  if (!entry) return null

  return (
    <CVEntryEdit
      entry={entry}
      onSave={(entry: Partial<CVEntry>) => {
        updateEntry(entry)
        history.goBack()
      }}
      onCancel={() => history.goBack()}
    />
  )
}

export default EditCVEntryPage
