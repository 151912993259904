import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CVEntry } from '../../hooks/useCVEntry'

interface setEntriesPayload {
  cvEntries: CVEntry[]
}

const initialState: CVEntry[] = []

const CVEntrySlice = createSlice({
  name: 'cvEntry',
  initialState,
  reducers: {
    setEntries(state, action: PayloadAction<{ cvEntries: CVEntry[] }>) {
      return action.payload.cvEntries
    },
    cacheEntry(state, action: PayloadAction<{ cvEntry: CVEntry }>) {
      const actionEntry = action.payload.cvEntry
      let entryIndex = state.findIndex(
        stateEntry => stateEntry.id === actionEntry.id
      )
      if (entryIndex >= 0) {
        state[entryIndex] = actionEntry
      } else {
        return [...state, actionEntry]
      }
    },
  },
})

export const { cacheEntry, setEntries } = CVEntrySlice.actions

export default CVEntrySlice.reducer
