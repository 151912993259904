/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import ResumeForm from './ResumeForm'
import useResume from '../../../hooks/useResume'

interface MatchParams {
  resumeId: string
}

const EditResumePage = ({
  match,
  history,
}: RouteComponentProps<MatchParams>) => {
  const { resumeId } = match.params
  const { resume, isLoaded, updateResume } = useResume(resumeId)

  if (!isLoaded || resume === undefined) return null

  return (
    <Fragment>
      <ResumeForm
        resume={resume}
        key={resume.id}
        onUpdate={resume => {
          updateResume(resume)
          history.goBack()
        }}
        onCancel={() => history.goBack()}
      />
    </Fragment>
  )
}

export default EditResumePage
