import useResumes from './useResumes'
import { Resume } from './useResumes'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/rootReducer'

const useResume = (resumeId: string) => {
  const {
    selectEntryForResume: collectionSelectEntryForResume,
    deSelectEntryForResume: collectionDeSelectEntryForResume,
    updateResume: collectionUpdateResume,
    deleteResume: collectionDeleteResume,
  } = useResumes()

  const resume = useSelector((state: RootState) =>
    state.resumes.find(resume => resume.id === resumeId)
  )

  const selectEntryForResume = (entryId: string) =>
    collectionSelectEntryForResume(resumeId, entryId)

  const deSelectEntryForResume = (entryId: string) =>
    collectionDeSelectEntryForResume(resumeId, entryId)

  const updateResume = (resumeUpdate: Partial<Resume>) =>
    collectionUpdateResume(resumeId, resumeUpdate)

  const deleteResume = () => collectionDeleteResume(resumeId)

  const isLoaded = !!resume

  return {
    resume,
    updateResume,
    isLoaded,
    deleteResume,
    selectEntryForResume,
    deSelectEntryForResume,
  }
}

export default useResume
