import { useEffect } from 'react'
import firebase from '../firebase'
import useAccount from './useAccount'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/rootReducer'
import { setCategories } from '../modules/entries/CVCategories.slice'
import sortedArray from '../helpers/sortedArray'
import byWeight, { WithWeight } from '../helpers/byWeight'
import useRegistrations from './useRegistrations'

export interface CVCategory extends WithWeight {
  title: string
  id: string
}

const useCVCategories = () => {
  const { uid, isLoggedIn } = useAccount()
  const db = firebase.firestore()
  const cvCategories = useSelector((state: RootState) =>
    [...state.cvCategories].sort(byWeight)
  )
  const { isRegistered, addRegistration } = useRegistrations()
  const dispatch = useDispatch()

  const cvCategoryCollectionPath = `users/${uid}/cvCategories`

  useEffect(() => {
    if (!isLoggedIn) return
    if (isRegistered(cvCategoryCollectionPath)) return
    addRegistration(cvCategoryCollectionPath)
    db.collection(cvCategoryCollectionPath).onSnapshot(snapshot => {
      const data = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })) as CVCategory[]
      dispatch(setCategories({ cvCategories: data }))
    })
  }, [
    db,
    isLoggedIn,
    cvCategoryCollectionPath,
    dispatch,
    isRegistered,
    addRegistration,
  ])

  const createCategory = () => {
    db.collection(cvCategoryCollectionPath).add({
      title: 'New Category',
      weight: cvCategories.length,
    })
  }

  const deleteCategory = (id: string) => {
    db.collection(cvCategoryCollectionPath)
      .doc(id)
      .delete()
  }

  const updateCategory = (id: string, newCategory: Partial<CVCategory>) => {
    db.collection(cvCategoryCollectionPath)
      .doc(id)
      .update(newCategory)
  }

  const moveCategoryUp = (id: string) => {
    const sortedCategories = sortedArray(cvCategories, byWeight)
    const index = sortedCategories.findIndex(category => category.id === id)
    if (index === 0) return
    const newWeight = index - 1
    updateCategory(sortedCategories[newWeight].id, { weight: index })
    updateCategory(id, { weight: newWeight })
  }

  const moveCategoryDown = (id: string) => {
    const sortedCategories = sortedArray(cvCategories, byWeight)
    const index = sortedCategories.findIndex(category => category.id === id)
    if (index === cvCategories.length - 1) return
    const newWeight = index + 1
    updateCategory(sortedCategories[newWeight].id, { weight: index })
    updateCategory(id, { weight: newWeight })
  }

  return {
    cvCategories,
    createCategory,
    deleteCategory,
    updateCategory,
    moveCategoryDown,
    moveCategoryUp,
  }
}

export default useCVCategories
