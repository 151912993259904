/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar'
import useAccount from './hooks/useAccount'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MaterialIcon from '@material/react-material-icon'
import IconButton from '@material/react-icon-button'
import { useTranslation } from 'react-i18next'
import DarkmodeButton from './modules/darkmode/DarkmodeButton'
import useDarkmode from './modules/darkmode/useDarkmode'

const Header = () => {
  const { logout, isLoggedIn } = useAccount()
  const { isDarkmode } = useDarkmode()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const isOnHomePage = location.pathname === '/'

  return (
    <header>
      <TopAppBar
        css={css`
          background-color: var(--theme-app-bar);
          & .mdc-top-app-bar__navigation-icon,
          & .mdc-top-app-bar__action-item {
            color: var(--theme-on-app-bar);
          }
          ${isDarkmode
            ? css`
                border-bottom: 1px solid rgba(var(--theme-primary-rgb), 0.45);
              `
            : ''}
        `}
        fixed
        dense
      >
        <TopAppBarRow>
          <TopAppBarSection align="start">
            {isLoggedIn && isOnHomePage && (
              <TopAppBarIcon navIcon tabIndex={0}>
                <Link to="/">
                  <MaterialIcon hasRipple icon="home" />
                </Link>
              </TopAppBarIcon>
            )}
            {isLoggedIn && !isOnHomePage && (
              <TopAppBarIcon navIcon tabIndex={0}>
                <IconButton onClick={() => history.goBack()}>
                  <MaterialIcon hasRipple icon="arrow_back" />
                </IconButton>
              </TopAppBarIcon>
            )}
            <TopAppBarTitle>
              <Link
                css={css`
                  color: white;
                  text-decoration: none;
                `}
                to="/"
              >
                MoVi
              </Link>
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection align="end" role="toolbar">
            <DarkmodeButton />
            {isLoggedIn && (
              <TopAppBarIcon actionItem tabIndex={0}>
                <MaterialIcon
                  aria-label={t('header.logout_aria')}
                  hasRipple
                  icon="logout"
                  onClick={logout}
                />
              </TopAppBarIcon>
            )}
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
    </header>
  )
}

export default Header
