/** @jsx jsx */
import { jsx, css, keyframes, Global } from '@emotion/core'
import { Fragment } from 'react'
import { Route, useLocation, Switch } from 'react-router'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ProtectedRoute from '../../ProtectedRoute'
import Login from '../login/Login'
import Home from '../home/Home'
import EntriesModule from '../entries/EntriesModule'
import ResumesModule from '../resumes/ResumesModule'
import ProfileModule from '../profile/ProfileModule'
import Account from '../account/Account'

const pageIntro = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const AppModule = () => {
  const location = useLocation()
  return (
    <Fragment>
      <Global
        styles={css`
          .animated-page-enter {
            animation: ${pageIntro} 0.3s ease forwards;
          }
          .animated-page-exit {
            /* Immediately remove pages that are exiting. */
            display: none;
          }
        `}
      />
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="animated-page"
          timeout={300}
        >
          <Switch location={location}>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute path="/entries" component={EntriesModule} />
            <ProtectedRoute path="/account" component={Account} />
            <ProtectedRoute path="/resumes" component={ResumesModule} />
            <ProtectedRoute path="/profile" component={ProfileModule} />
            <Route path="/login" component={Login} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  )
}

export default AppModule
