/**
 * Private route.
 * After the recipe at:
 * https://reacttraining.com/react-router/web/example/auth-workflow
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Route, Redirect } from 'react-router'
import useAccount from './hooks/useAccount'
import { ReactComponentLike } from 'prop-types'
import Spinner from './components/Spinner'
import { Headline3 } from '@material/react-typography'

const ProtectedRoute = ({
  component: Component,
  ...rest
}: {
  component: ReactComponentLike
  path?: string
  exact?: boolean
}) => {
  const { isLoggedIn, userLoaded } = useAccount()
  return (
    <Route
      {...rest}
      render={props => {
        if (!userLoaded)
          return (
            <div
              css={css`
                position: absolute;
                top: 20%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
              `}
            >
              <Headline3>MoVi</Headline3>
              <Spinner />
            </div>
          )
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedRoute
