/** @jsx jsx */
import { jsx } from '@emotion/core'
import CVEntryEdit from './CVEntryEdit'
import useCVEntries from '../../hooks/useCVEntries'
import { CVEntry } from '../../hooks/useCVEntry'
import { RouteComponentProps } from 'react-router'

const createBasicEntry = (): Partial<CVEntry> => {
  const now = new Date()
  return {
    end: {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
    },
    start: {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
    },
  }
}

interface MatchParams {
  categoryId: string
}

const NewCVEntry = ({ match, history }: RouteComponentProps<MatchParams>) => {
  const categoryId = match.params.categoryId
  const { addEntry } = useCVEntries(categoryId)
  return (
    <CVEntryEdit
      onSave={(entry: Partial<CVEntry>) => {
        addEntry(entry)
        history.push('/entries')
      }}
      entry={createBasicEntry()}
      onCancel={() => {
        history.goBack()
      }}
    />
  )
}

export default NewCVEntry
