/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import useProfilPictures from '../../hooks/useProfilePictures'
import { Button } from '@material/react-button'
import ProfilePictureItem from './ProfilePictureItem'
import { withRouter, RouteComponentProps } from 'react-router'

const ProfilePictures = ({ history }: RouteComponentProps) => {
  const { pictures, uploadPicture, isUploading } = useProfilPictures()

  const selectAndUpload = () => {
    const fileSelector = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    fileSelector.setAttribute('accept', '.jpg,.png,.jpeg')
    fileSelector.addEventListener('change', (event: any) => {
      const files = event.target.files as FileList
      uploadPicture(files[0])
    })
    fileSelector.click()
  }

  return (
    <section>
      <ul
        css={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          margin-bottom: 0;
        `}
      >
        {pictures.map(picture => (
          <ProfilePictureItem
            onClick={() => history.push(`/profile/pictures/${picture.id}`)}
            picture={picture}
            key={picture.id}
          />
        ))}
      </ul>

      <Button
        raised={pictures.length === 0}
        disabled={isUploading}
        onClick={selectAndUpload}
      >
        {isUploading ? 'Uploading...' : 'Upload picture'}
      </Button>
    </section>
  )
}
export default withRouter(ProfilePictures)
