import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from '../../hooks/useProfile'

export interface setProfilePayload {
  profile: Profile
}

const initialState = {
  profile: {
    id: '',
  } as Profile,
  isLoaded: false,
}

const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<setProfilePayload>) {
      state.profile = action.payload.profile
      state.isLoaded = true
    },
  },
})

export const { setProfile } = ProfileSlice.actions

export default ProfileSlice.reducer
