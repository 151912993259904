import { useState, useEffect, useCallback } from 'react'

const maxMobile = 500

const useBreakpoints = () => {
  // Use a callback so this doesn't cause the effect to re-create
  // on each render.
  const getBreakpoints = useCallback(() => {
    const width = window.innerWidth
    const height = window.innerHeight
    return {
      width,
      height,
      isMobile: width < maxMobile,
    }
  }, [])

  const [breakpoints, setBreakpoints] = useState(getBreakpoints())

  useEffect(() => {
    function handleResize() {
      setBreakpoints(getBreakpoints())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getBreakpoints])

  return breakpoints
}

export default useBreakpoints
