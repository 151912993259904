import { combineReducers } from '@reduxjs/toolkit'
import cvCategories from '../modules/entries/CVCategories.slice'
import cvEntries from '../modules/entries/CVEntries.slice'
import account from '../modules/account/account.slice'
import profile from '../modules/profile/Profile.slice'
import profilePictures from '../modules/profile/profilePicture.slice'
import resumes from '../modules/resumes/resume.slice'

const rootReducer = combineReducers({
  account,
  cvCategories,
  cvEntries,
  profile,
  profilePictures,
  resumes,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
