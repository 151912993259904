import React, { useState, ReactElement } from 'react'
import DarkmodeContext, { DarkmodeContextValue } from './darkmodeContext'

const DarkmodeContextProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[]
}) => {
  const [enabled, setEnabled] = useState<boolean>(false)

  const toggle = () => setEnabled(!enabled)

  const value: DarkmodeContextValue = { enabled, toggle }

  return (
    <DarkmodeContext.Provider value={value}>
      {children}
    </DarkmodeContext.Provider>
  )
}

export default DarkmodeContextProvider
