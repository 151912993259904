/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import Button from '@material/react-button'
import useCVCategories from '../../hooks/useCVCategories'
import CVCategory from './CVCategory'
import PageHeadline from '../../components/PageHeadline'
import { useTranslation } from 'react-i18next'

const Entries = () => {
  const {
    createCategory,
    deleteCategory,
    updateCategory,
    moveCategoryDown,
    moveCategoryUp,
    cvCategories,
  } = useCVCategories()
  const { t } = useTranslation()

  return (
    <Fragment>
      <PageHeadline>{t('entries.headline_entries')}</PageHeadline>

      {cvCategories.map(category => (
        <CVCategory
          key={category.id}
          onDelete={() => deleteCategory(category.id)}
          category={category}
          onTitleChange={title => updateCategory(category.id, { title })}
          onMoveUp={() => moveCategoryUp(category.id)}
          onMoveDown={() => moveCategoryDown(category.id)}
        />
      ))}
      <Button
        css={css`
          margin-top: 2rem;
          margin-bottom: 1rem;
        `}
        onClick={createCategory}
        raised={cvCategories.length === 0}
      >
        {t('categories.add_category')}
      </Button>
    </Fragment>
  )
}

export default Entries
