export interface WithWeight {
  weight?: number
}

const byWeight = (a: WithWeight, b: WithWeight) => {
  if (a.weight !== undefined && b.weight !== undefined)
    return a.weight - b.weight
  if (a.weight !== undefined && b.weight === undefined) return -1
  if (b.weight !== undefined && a.weight === undefined) return 1
  return 0
}

export default byWeight
