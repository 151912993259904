/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { CVCategory } from '../../hooks/useCVCategories'
import MaterialIcon from '@material/react-material-icon'
import { Button } from '@material/react-button'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import List, { ListItem } from '@material/react-list'
import useCVEntries from '../../hooks/useCVEntries'
import { CVEntry } from '../../hooks/useCVEntry'
import EditableTitle from '../../components/EditableTitle'
import Card, {
  CardActions,
  CardActionButtons,
  CardActionIcons,
} from '@material/react-card'
import CVEntryListItem from '../../components/CVEntryListItem'
import IconMenu from '../../components/IconMenu'
import {
  MenuListItem,
  MenuListItemText,
  MenuListDivider,
} from '@material/react-menu'
import { useTranslation } from 'react-i18next'

const CVCategoryComponent = ({
  category,
  onDelete,
  onTitleChange,
  history,
  onMoveUp,
  onMoveDown,
}: RouteComponentProps & {
  category: CVCategory
  onDelete: () => void
  onTitleChange: (newTitle: string) => void
  onMoveUp: () => void
  onMoveDown: () => void
}) => {
  const { entries, deleteEntry } = useCVEntries(category.id)
  const { t } = useTranslation()

  return (
    <Card
      css={css`
        padding: 1rem;
        margin-bottom: 2rem;
      `}
    >
      <EditableTitle
        title={category.title || '.'}
        label={t('categories.category_title_label')}
        onTitleChange={onTitleChange}
      ></EditableTitle>

      {entries && (
        <List twoLine>
          {entries.map((entry: CVEntry) => (
            <ListItem
              key={entry.id}
              onClick={() => history.push(`/entries/${entry.id}/edit`)}
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <CVEntryListItem
                meta={
                  <IconMenu>
                    <MenuListItem
                      onClick={() => history.push(`/entries/${entry.id}/edit`)}
                    >
                      <MenuListItemText primaryText={t('button.edit')} />
                    </MenuListItem>
                    <MenuListItem
                      onClick={e => {
                        e.stopPropagation()
                        deleteEntry(entry.id)
                      }}
                    >
                      <MenuListItemText primaryText={t('button.delete')} />
                    </MenuListItem>
                  </IconMenu>
                }
                entry={entry}
              />
            </ListItem>
          ))}
        </List>
      )}

      <CardActions>
        <CardActionButtons>
          <Button
            css={css`
              margin-top: 1rem;
            `}
            onClick={() => history.push(`/entries/new-entry/${category.id}`)}
          >
            {t('entries.new_entry')}
          </Button>
        </CardActionButtons>

        <CardActionIcons>
          <IconMenu
            ButtonElement={
              <Button
                trailingIcon={
                  <MaterialIcon
                    css={css`
                      color: var(--theme-text);
                    `}
                    icon="more_vert"
                  />
                }
              >
                <span
                  css={css`
                    color: var(--theme-text);
                  `}
                >
                  {t('button.more')}
                </span>
              </Button>
            }
          >
            <MenuListItem onClick={onMoveUp}>
              <MenuListItemText
                primaryText={t('categories.move_category_up')}
              />
            </MenuListItem>
            <MenuListItem onClick={onMoveDown}>
              <MenuListItemText
                primaryText={t('categories.move_category_down')}
              />
            </MenuListItem>
            <MenuListDivider />
            <MenuListItem onClick={onDelete}>
              <MenuListItemText
                primaryText={`${t('button.delete')} ${category.title}`}
              />
            </MenuListItem>
          </IconMenu>
        </CardActionIcons>
      </CardActions>
    </Card>
  )
}

export default withRouter(CVCategoryComponent)
