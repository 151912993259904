import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface loginPayload {
  uid: string
}

const initialState = {
  isLoggedIn: false,
  // Tracks that we tried a login.
  userLoaded: false,
  uid: 'placeholder',
}

const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login(state, action: PayloadAction<loginPayload>) {
      state.uid = action.payload.uid
      state.isLoggedIn = true
      state.userLoaded = true
    },
    logout(state) {
      state.uid = 'placeholder'
      state.isLoggedIn = false
      state.userLoaded = true
    },
    noUserFound(state) {
      state.uid = 'placeholder'
      state.isLoggedIn = false
      state.userLoaded = true
    },
  },
})

export const { login, logout, noUserFound } = AccountSlice.actions

export default AccountSlice.reducer
