import React from 'react'
import Select from '@material/react-select'

const monthOptions = [
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
]

const MonthSelect = ({
  value,
  onChange,
  label,
  outlined,
}: {
  value: string
  onChange: (newValue: string) => void
  label: string
  outlined?: boolean
}) => {
  return (
    <Select
      outlined={outlined}
      label={label}
      value={value}
      onChange={evt => onChange(evt.currentTarget.value)}
      options={monthOptions}
    ></Select>
  )
}
export default MonthSelect
