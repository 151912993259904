import React from 'react'

export interface DarkmodeContextValue {
  enabled: boolean
  toggle: () => void
}

export const defaultDarkmodeValue: DarkmodeContextValue = {
  enabled: false,
  toggle: () => {},
}

const DarkmodeContext = React.createContext<DarkmodeContextValue>(
  defaultDarkmodeValue
)

export default DarkmodeContext
