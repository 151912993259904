/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import { BrowserRouter as Router } from 'react-router-dom'

// ALL the css for Material components used.
import '@material/react-material-icon/dist/material-icon.css'
import '@material/react-card/dist/card.css'
import '@material/react-button/dist/button.css'
import '@material/react-top-app-bar/dist/top-app-bar.css'
import '@material/react-icon-button/dist/icon-button.css'
import '@material/react-text-field/dist/text-field.css'
import '@material/react-list/dist/list.css'
import '@material/react-typography/dist/typography.css'
import '@material/react-select/dist/select.css'
import '@material/react-checkbox/dist/checkbox.css'
import '@material/react-menu-surface/dist/menu-surface.css'
import '@material/react-menu/dist/menu.css'
import '@material/react-chips/dist/chips.css'
import '@material/react-drawer/dist/drawer.css'
import '@material/react-snackbar/dist/snackbar.css'

import NavigationDrawer from './NavigationDrawer'
import SnackContextProvider from './modules/snacks/SnackContextProvider'
import { Suspense } from 'react'
import AppLoadingOverlay from './components/AppLoadingOverlay'
import useDarkmode from './modules/darkmode/useDarkmode'

const App = () => {
  const { isDarkmode } = useDarkmode()
  return (
    <SnackContextProvider>
      <Router>
        <Suspense fallback={<AppLoadingOverlay />}>
          <Global
            styles={css`
              :root {
                --color-green: #a7cf45;
                --color-text: #191a17;
                --color-red: #fb6107;

                --theme-primary: var(--color-green);
                --theme-primary-rgb: 167, 207, 69;
                --theme-accent: #fbb02d;
                --theme-text: var(--color-text);
                --theme-alert: var(--color-red);
                --theme-highlight: #eff5df;
                --theme-background: #fff;
                --theme-surface-background: #fff;
                --theme-disabled: #e0e0e0;
                --theme-app-bar: var(--theme-primary);
                --theme-on-app-bar: var(--theme-background);

                --mdc-theme-primary: var(--theme-primary);
                --mdc-theme-secondary: var(--theme-accent);

                /* On backgorund colors are text color in rgba with material design opacity. */
                --mdc-theme-text-primary-on-background: rgba(27, 28, 26, 0.85);
                --mdc-theme-text-secondary-on-background: rgba(
                  27,
                  28,
                  26,
                  0.45
                );
                --mdc-theme-surface: var(--theme-surface-background);
                --mdc-theme-on-primary: var(--theme-on-primary);

                ${isDarkmode
                  ? css`
                      --color-green: #a7cf45;
                      --color-text: rgba(255, 255, 255, 0.87);
                      --color-red: #cf6679;

                      --theme-primary: var(--color-green);
                      --theme-primary-rgb: 167, 207, 69;
                      --theme-accent: #e1c445;
                      --theme-text: var(--color-text);
                      --theme-alert: var(--color-red);
                      --theme-highlight: #cbccc8;
                      --theme-background: #121212;
                      --theme-surface-background: #20201e;
                      --theme-disabled: #2e2e2e;
                      --theme-app-bar: #252525;
                      --theme-on-primary: #121212;
                      --theme-on-app-bar: var(--theme-text);

                      --mdc-theme-primary: var(--theme-primary);
                      --mdc-theme-secondary: var(--theme-accent);

                      /* On backgorund colors are text color in rgba with material design opacity. */
                      --mdc-theme-text-primary-on-background: rgba(
                        255,
                        255,
                        255,
                        0.85
                      );
                      --mdc-theme-text-secondary-on-background: rgba(
                        255,
                        255,
                        255,
                        0.45
                      );
                      --mdc-theme-surface: var(--theme-surface-background);
                      --mdc-theme-on-primary: var(--theme-on-primary);

                      .mdc-list-item__text {
                        color: var(--theme-text);
                      }
                      #root
                        .mdc-text-field:not(.mdc-text-field--disabled)
                        .mdc-text-field__input {
                        color: var(--mdc-theme-text-primary-on-background);
                      }
                      #root .mdc-text-field:not(.mdc-text-field--disabled) {
                        background-color: var(--theme-surface-background);
                      }
                      #root .mdc-floating-label {
                        color: var(--mdc-theme-text-secondary-on-background);
                      }
                      .mdc-text-field--outlined:not(.mdc-text-field--disabled)
                        .mdc-notched-outline__leading,
                      .mdc-text-field--outlined:not(.mdc-text-field--disabled)
                        .mdc-notched-outline__notch,
                      .mdc-text-field--outlined:not(.mdc-text-field--disabled)
                        .mdc-notched-outline__trailing,
                      .mdc-select--outlined:not(.mdc-select--disabled)
                        .mdc-notched-outline__leading,
                      .mdc-select--outlined:not(.mdc-select--disabled)
                        .mdc-notched-outline__notch,
                      .mdc-select--outlined:not(.mdc-select--disabled)
                        .mdc-notched-outline__trailing {
                        border-color: var(
                          --mdc-theme-text-secondary-on-background
                        );
                      }
                    `
                  : ''}
              }
              * {
                font-family: 'Roboto', sans-serif;
              }
              html,
              body,
              #root {
                height: 100%;
                padding: 0;
                margin: 0;
                color: var(--theme-text);
                background-color: var(--theme-background);
              }
              h1 {
                font-weight: 300;
                font-size: 3rem;
              }
              h2 {
                font-weight: 300;
                font-size: 2rem;
              }
              h3 {
                font-weight: 300;
                font-size: 1.5rem;
                margin-top: 2em;
              }
              a {
                color: var(--theme-text);
              }
              p {
                max-width: 35rem;
              }

              /* Color modifiers, using important to not need specificity. */
              .color_text {
                color: var(--theme-text) !important;
              }

              .button_text {
                border-color: var(--theme-text) !important;
                color: var(--theme-text) !important;
              }

              .mdc-list-divider {
                /* Fix divider creating a dot. */
                list-style: none;
              }

              #root
                .mdc-text-field--focused:not(.mdc-text-field--disabled)
                .mdc-floating-label {
                color: var(--theme-primary);
              }

              #root .mdc-select--outlined:not(.mdc-select--disabled) {
                background-color: var(--theme-surface-background);
              }

              #root
                .mdc-select:not(.mdc-select--disabled)
                .mdc-select__native-control,
              #root
                .mdc-select:not(.mdc-select--disabled)
                .mdc-select__selected-text {
                color: var(--theme-text);
              }

              .mdc-card__actions {
                flex-wrap: wrap;
              }

              #root
                .mdc-select:not(.mdc-select--disables).mdc-select--focused
                .mdc-floating-label {
                color: var(--theme-primary);
              }

              #root .mdc-chip {
                background-color: var(--theme-surface-background);
                color: var(--theme-text);
              }
              #root .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before {
                background-color: var(--theme-accent);
              }
              #root .mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
                border: 1px solid var(--theme-accent);
              }
              #root .mdc-button--raised:disabled {
                background-color: var(--theme-disabled);
              }

              #root
                .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
                ~ .mdc-checkbox__background {
                border-color: var(--mdc-theme-text-secondary-on-background);
              }
            `}
          />
          <NavigationDrawer />
        </Suspense>
      </Router>
    </SnackContextProvider>
  )
}

export default App
