import { useContext } from 'react'
import DarkmodeContext from './darkmodeContext'

const useDarkmode = () => {
  const { enabled: isDarkmode, toggle: toggleDarkmode } = useContext(
    DarkmodeContext
  )

  return { isDarkmode, toggleDarkmode }
}

export default useDarkmode
